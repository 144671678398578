.App-banner {
  max-width: 100%;
  width: 100%;
  height: auto;
  background-size: cover;
}

.App-banner h2 {
  font-size: 60px;
  color: white;
  margin: 0;
}

.App-banner h3 {
  font-size: 35px;
  color: white;
  margin: 0;
  font-weight: 500;
}

.Banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 700px) {
  .Banner-container {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
  .Socials-container {
    justify-content: center;
    align-items: center;
  }
}

.Banner-headshot {
  width: 160px;
  height: auto;
  border-radius: 50%;
  margin: 60px 0 30px;
}

.Socials-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 18px;
  gap: 15px;
}

.Socials-container img {
  width: 50px;
  height: auto;
}
