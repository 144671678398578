.Project {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
}

.Project-col-left {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Project-col-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Project-image {
  width: 570px;
  max-width: 100%;
  height: auto;
}

.Project-info h3 {
  text-align: center;
  margin: 0;
}
.Project-info p {
  text-align: center;
}

.Project-year {
  color: #bbb;
  font-size: 16px;
  margin: 8px 0;
}

.Project-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.Project-button {
  margin: 0 10px;
}

@media screen and (min-width: 800px) {
  .Project {
    flex-direction: row;
  }
  .Project-col-left {
    width: 50%;
    display: flex;
  }

  .Project-col-right {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-left: 18px;
  }

  .Project-info h3 {
    text-align: left;
  }
  .Project-info p {
    text-align: left;
  }

  .Project-buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
  }

  .Project-button {
    margin-right: 10px;
    margin-left: 0px;
  }
}
