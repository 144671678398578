.App {
  text-align: center;
  color: white;
  background-color: #18171c;
  min-height: 100vh;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
}

.Wave {
}

li {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

a img {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

a:hover > img {
  background-color: rgba(255, 255, 255, 0.2);
}

hr {
  height: 1px;
  background-color: #26252c;
  border: none;
  margin: 0 30px;
}

section {
  padding: 40px 30px;
}

@media screen and (min-width: 700px) {
  section {
    padding: 40px 200px;
  }
  hr {
    margin: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  section {
    padding: 40px 220px;
  }
}

@media screen and (min-width: 2000px) {
  section {
    padding: 40px 450px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
