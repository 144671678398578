.About {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.About-row {
  display: flex;
  flex-direction: column-reverse;
}

.About-col {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1300px) {
  .About-row {
    flex-direction: row;
  }
  .About-col {
    width: 50%;
    margin: 0 18px;
  }
}

.About-col h3 {
  margin-top: 0;
}

.About-col p {
  text-align: left;
  text-indent: 2em;
}

.About-image {
  width: 100%;
  height: auto;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
