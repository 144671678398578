.NavBar {
  text-align: left;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding-top: 18px;
  padding-bottom: 18px;
  transition: background-color 0.35s ease;
}

.NavBar-sticky {
  background-color: rgb(32, 73, 184);
  transition: background-color 0.15s ease;
}

.NavBar h2 {
  text-align: left;
  margin: 0;
  color: white;
  display: none;
}

.NavBar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding: 0 0;
  width: 100%;
}

.NavBar-menu li {
  cursor: pointer;
  margin: 0 10px;
}

@media screen and (min-width: 700px) {
  .NavBar h2 {
    display: block;
  }
  .NavBar-menu {
    width: auto;
  }
}

#top-arrow {
  position: fixed;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
  background-color: rgb(32, 73, 184);
  border-radius: 50%;
  bottom: 70px;
  right: 10px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 10;
}

#top-arrow:hover {
  background-color: #1c40a5;
}

#top-arrow img {
  width: 20px;
  height: auto;
}
